class Person {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.modalId = this.element.dataset.micromodalTrigger ?? null;
    this.modal = document.querySelector(`#${this.modalId}`);

    if (!(this.modal instanceof HTMLElement)) {
      throw new TypeError("Modal doesn't exist");
    }

    this.modalContent = this.modal.querySelector(".person-details");
    this.modalImg = this.modal.querySelector(".person-modal__image img");

    if (this.modalId === null || this.modal === null) {
      throw new TypeError("Invalid data-modal-id attribute");
    }

    this.initEvents();
  }

  initEvents() {
    this.element.addEventListener("click", this.openModal.bind(this));
  }

  openModal(event) {
    event.preventDefault();
    const JSONString = event.currentTarget.dataset.object ?? "null";
    const JSONObject = JSON.parse(JSONString);

    const emailHTML = (typeof JSONObject.email_address !== "undefined" && JSONObject.email_address.length) ? `
      <li>
        <a href="mailto:${JSONObject.email_address}" class="styled-link">
          <span>${JSONObject.email_address}</span>
          <svg><use xlink:href="#icon--arrow"></use></svg>
        </a>
      </li>
    ` : "";

    const phoneHTML = (typeof JSONObject.phone_number !== "undefined" && JSONObject.phone_number.length) ? `
      <li>
        <a href="tel:${JSONObject.phone_number}" class="styled-link">
          <span>${JSONObject.phone_number}</span>
          <svg><use xlink:href="#icon--arrow"></use></svg>
        </a>
      </li>
    ` : "";

    let expertiseHTML = "";

    if(JSONObject.expertise_items && JSONObject.expertise_items.length) {
      const firstName = this.pluralize((JSONObject.name ?? "").split(" ")[0] ?? "");

      expertiseHTML = JSONObject.expertise_items.map((link) => {
        return `
          <li>
            <a href="${link.url}" target="${link.target}" class="styled-link">
              <span>${link.title}</span>
              <svg><use xlink:href="#icon--arrow"></use></svg>
            </a>
          </li>
        `;
      }).join("");

      expertiseHTML = `
        <div class="person-details__expertise">
          <span>${firstName} Expertise</span>
          <ul class="person-details__links">${expertiseHTML}</ul>
        </div>
      `;
    }

    this.modalContent.innerHTML = `
      <h2 class="person-details__name">${JSONObject.name ?? ""}</h2>
      <span class="person-details__quali">${JSONObject.qualifications ?? ""}</span>
      <span class="person-details__job">${JSONObject.job_title ?? ""}</span>
      <ul class="person-details__contact">
        ${emailHTML ?? ""}
        ${phoneHTML ?? ""}
      </ul>
      <div class="person-details__bio">
        ${JSONObject.bio ?? ""}
      </div>
      ${expertiseHTML}
    `;

    if (typeof JSONObject.featured_image !== null) {
      this.modalImg.src = JSONObject.featured_image;
      this.modalImg.setAttribute("alt", `${JSONObject.name} Profile Image`);
    }
  }

  pluralize(string) {
    if(!string || !string.length)
      return "";

    const lastChar = string.charAt(string.length - 1);

    if(lastChar === "s") {
      return string + `'`
    }

    return string + `'s`;
  }
}

export default Person;
