class PageNavigation {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.initialised = false;
    this.mobileTriggers = this.element.querySelectorAll('.menu-toggle');

    this.initEvents();
  }

  initEvents() {
    if(this.mobileTriggers){
      this.mobileTriggers.forEach((el) => {
        el.addEventListener("click", this.toggleMobileNav.bind(this));
      });
    }

    (this.element.querySelectorAll(".menu-item-has-children > a") ?? []).forEach(el => {
      el.addEventListener("click", this.toggleSubMenu.bind(this));
    });
  }

  toggleSubMenu(ev){
    ev.preventDefault();
    (this.element.querySelectorAll(".open") ?? []).forEach(el => el.classList.remove("open"));
    ev.currentTarget.classList.toggle("open");
  }

  toggleMobileNav(event) {
    event.preventDefault();

    const btn = event.currentTarget;
    const isAlreadyOpen = window.document.documentElement.classList.contains("site-menu-open");

    const text = btn.querySelector('.menu-toggle__text');

    if(text){
      text.innerText = isAlreadyOpen ? "Menu" : "Close";
    }

    window.document.documentElement.classList.toggle("site-menu-open");
  }
}

export default PageNavigation;
