import Swiper, {Navigation} from 'swiper';
import Panel from "./Panel";

class CardSwiper {
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError("Invalid element");
		}

		this.element = element;
		this.fullWidth = typeof this.element.dataset.fullWidth !== "undefined";
		this.section = this.element.closest(".section");
		this.swiperEl = this.element.querySelector(".swiper");
		this.swiper = null;
		this.initSwiper();
	}

	initSwiper() {
		let args = {
			allowTouchMove: true,
			modules: [Navigation],
			allowClick: true,
			slidesPerView: "auto",
			spaceBetween: 20,
			navigation: {
				nextEl: this.section.querySelector(".swiper-button.next"),
				prevEl: this.section.querySelector(".swiper-button.prev"),
			},
			on: {
				init: (swiper) => {
					swiper.slides.forEach(el => {
						const card = el.querySelector('.panel');

						if (!card)
							return null;

						return new Panel(card);
					});
				}
			}
		};

		if (this.fullWidth) {
			args = {
				...args,
				loop: true,
				initialSlide: 2,
				breakpoints: {
					1200: {
						loop: true,
						slidesPerView: 4,
						centeredSlides: false,
						allowTouchMove: false
					}
				}
			}
		}

		this.swiper = new Swiper(this.swiperEl, args);
	}
}

export default CardSwiper;
