import AOS from 'aos';

export default {
  init() {
    const isCrawler = this.isWebCrawler();

    if (isCrawler) {
      const aosElements = document.querySelectorAll('[data-aos]');

      Array.from(aosElements)
        .forEach(el => {
          if (el.dataset.aos) {
            el.dataset.noAos = el.dataset.aos;
            el.dataset.aos = "false";
          }
        });
    } else {
      AOS.init({
        disable: 'mobile',
        duration: 1000,
        once: true
      });
    }
  },

  isWebCrawler() {
    return (
      /bot|crawler|spider|crawling/i.test(navigator.userAgent)
      || window.matchMedia("(prefers-reduced-motion: reduce)").matches
    )
  }
}
