/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import MicroModal from "micromodal";

import ParallaxGallery from "./modules/ParallaxGallery";
import CardSwiper from "./modules/CardSwiper";
import Person from "./modules/Person";
import TimelineSwiper from "./modules/TimelineSwiper";
import PageNavigation from "./modules/PageNavigation";
import ZoomGallery from "./modules/ZoomGallery";
import Animations from "./modules/Animations";
import Panel from "./modules/Panel";
import FullWidthImage from "./modules/FullWidthImage";

window.namespace = {
	init: function() {
		this.helpers.initSvg();
		this.cssVars.init();

		Animations.init();

		(document.querySelectorAll('.section--gallery') ?? []).forEach(el => new ZoomGallery(el));
		(document.querySelectorAll('.parallax-gallery') ?? []).forEach(el => new ParallaxGallery(el));
		(document.querySelectorAll('.card-swiper') ?? []).forEach(el => new CardSwiper(el));
		(document.querySelectorAll('.person') ?? []).forEach(el => new Person(el));
		(document.querySelectorAll('.panel-column .panel') ?? []).forEach(el => new Panel(el));
		(document.querySelectorAll('.timeline-slider') ?? []).forEach(el => new TimelineSwiper(el));
		(document.querySelectorAll('#page-navigation') ?? []).forEach(el => new PageNavigation(el));
		(document.querySelectorAll('.full-width-image') ?? []).forEach(el => new FullWidthImage(el));

		MicroModal.init({
			onShow: (modal) => {
				(modal.querySelectorAll("a") ?? []).forEach(el => el.blur())
				window.document.documentElement.classList.add("modal-open");
			},
			onClose: () => {
				window.document.documentElement.classList.remove("modal-open");
			},
			openClass: 'is-open',
			disableScroll: true,
			disableFocus: false,
			awaitOpenAnimation: false,
			awaitCloseAnimation: false,
		});

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});
	},
	cssVars: {
		init: function() {
			this.setVars();
			window.addEventListener("resize", this.setVars.bind(this));
		},
		setVars: function() {
			this.setWindowHeight();
			this.setWindowWidth();
			this.setNavigationHeight();
		},
		setWindowHeight: function() {
			document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
		},
		setWindowWidth: function() {
			document.documentElement.style.setProperty('--window-width', `${window.innerHeight}px`);
		},
		setNavigationHeight: function() {
			const header = document.querySelector("#page-navigation");

			if(header){
				document.documentElement.style.setProperty('--nav-height', `${header.offsetHeight}px`);
			}
		}
	},
	helpers: {
		requireAll: function(r) {
			r.keys().forEach(r);
		},
		initSvg: function (){
			svgxhr({
				filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
			});
		}
	}
};

window.namespace.init();

if(typeof NodeList.prototype.forEach !== 'function'){
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.namespace.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
