import Swiper, {Navigation} from 'swiper';

class TimelineSwiper {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid element");
        }

        this.element = element;
        this.swiper = null;
        this.bar = this.element.querySelector(".timeline-bar");
        this.initSwiper();
    }

    initSwiper() {
        this.swiper = new Swiper(this.element, {
            allowTouchMove: true,
            allowClick: true,
            modules: [Navigation],
            spaceBetween: 115,
            slidesPerView: "auto",
            speed: 1000,
            navigation: {
                nextEl: this.element.querySelector(".swiper-button.next"),
                prevEl: this.element.querySelector(".swiper-button.prev"),
            },
            on: {
                init: (swiper) => {
                    if(this.bar){
                        const width = ((swiper.virtualSize / swiper.slides.length) * (swiper.slides.length - 1) + 95);
                        this.bar.style.width = `${width}px`;
                    }
                }
            }
        });
    }
}

export default TimelineSwiper;
