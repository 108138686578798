import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import checkIsMobile from "../lib/checkIsMobile";

gsap.registerPlugin(ScrollTrigger);

class ZoomGallery {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.grid = this.element.querySelector(".gallery-grid");
    this.middle = this.grid.querySelector(".gallery-grid__item.index-3");
    this.other = this.grid.querySelectorAll(".gallery-grid__item:not(.index-3)");

    this.args = {
      scrollTrigger: {
        trigger: this.element,
        start: "0 top",
        end: "bottom 0",
        scrub: 1
      }
    };

    if(window.innerWidth > 1200 && !checkIsMobile()){
      this.fixGridTop();
      this.init();
    }
  }

  init() {
    const centerArgs = {
      ...this.args,
      scrollTrigger: {
        ...this.args.scrollTrigger,
        onEnterBack: () => {
          this.element.style.zIndex = 11;
        },
        onLeave: () => {
          this.element.style.zIndex = -1;
        }
      }
    };

    gsap.timeline(centerArgs)
      .to(this.grid, {
        keyframes: [
          {scale: 5, duration: .85},
          {yPercent: -100, duration: 1},
        ],
      });

    if (this.other) {
      this.other.forEach((el) => {
        let keyframes = [
          {opacity: 0, scale: 1.4, duration: .85},
          {opacity: 0, scale: 1.4, duration: .86},
        ];

        if(el.classList.contains("index-0")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: -20, yPercent: -35, duration: .67}, ...keyframes];
        }
        if(el.classList.contains("index-1")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: 20, yPercent: -35, duration: .67}, ...keyframes];
        }
        if(el.classList.contains("index-2")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: -60, yPercent: -20, duration: .67}, ...keyframes];
        }
        if(el.classList.contains("index-4")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: 20, yPercent: 0, duration: .67}, ...keyframes];
        }
        if(el.classList.contains("index-5")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: -30, yPercent: 0, duration: .67}, ...keyframes];
        }
        if(el.classList.contains("index-6")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: 0, yPercent: 60, duration: .67}, ...keyframes];
        }
        if(el.classList.contains("index-7")){
          keyframes = [{opacity: 1, scale: 1.4, xPercent: 20, yPercent: 20, duration: .67}, ...keyframes];
        }

        gsap.timeline(this.args)
          .to(el, {
            keyframes,
          });
      });
    }
  }

  fixGridTop() {
    const windowHeight = window.innerHeight;
    const gridHeight = this.grid.offsetHeight ?? 2;

    const offset = (windowHeight / 2) - (gridHeight / 2);

    this.grid.style.top = `${offset}px`;
  }
}

export default ZoomGallery;
