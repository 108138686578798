class Panel {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid element");
        }

        this.element = element;
        this.content = this.element.querySelector(".panel__content");
        this.title = this.element.querySelector(".panel__title");
        this.height = 38;

        this.setHeights();
        window.addEventListener("resize", this.setHeights, { passive: true });
    }

    setHeights() {
        if(this.title !== null && typeof this.title !== "undefined"){
            this.height = this.title.offsetHeight;

            console.log({el: this.content, height: this.content.offsetHeight});

            if(this.content !== null){
                this.element.style.setProperty('--el-height', `${this.content.offsetHeight}px`);
                this.content.style.height = `${this.height}px`;
                setTimeout(() => this.element.classList.add("initialized"), 1);
            }
        }
    }
}

export default Panel;
