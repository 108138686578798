import {gsap} from "gsap";
import checkIsMobile from "../lib/checkIsMobile";

class FullWidthImage {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;

    this.args = {
      scrollTrigger: {
        trigger: this.element,
        start: "top center",
        end: "+=300",
        scrub: 1,
      }
    };

    if(window.innerWidth > 1200 && !checkIsMobile()){
      this.init();
    }
  }

  init() {
    this.tween = gsap.timeline(this.args)
      .fromTo(
        this.element,
        {
          y: 0,
          clipPath: "polygon(20% 0px, 80% 0px, 80% 100%, 20% 100%)"
        },
        {
          y: 0,
          clipPath: "polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%)",
          duration: 1.5
        }
      );
  }
}

export default FullWidthImage;
