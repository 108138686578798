/** Images Gallery Section */
import {gsap} from "gsap";

class ParallaxGallery {
  constructor(element) {
    if (!(element instanceof HTMLElement)) {
      throw new TypeError("Invalid element");
    }

    this.element = element;
    this.rows = this.element.querySelectorAll(".gallery-row");
    this.rowObjects = [];

    this.args = {
      scrollTrigger: {
        trigger: this.element,
        start: "-2000 top",
        end: "+=4000",
        scrub: 1,
      }
    };

    this.initRows();
  }
  initRows() {
    if(typeof this.rows !== "undefined" && this.rows.length){
      this.rows.forEach((el, idx) => {
        const innerEl = el.querySelector(".swiper-wrapper");
        const keyframe = el.classList.contains("even") ? {xPercent: 100, duration: 1} : {xPercent: -100, duration: 1}

        if(innerEl) {
          const row = gsap.timeline(this.args)
            .to(el.querySelector(".swiper-wrapper"), {
              keyframes: [keyframe],
            });

          this.rowObjects.push(row);
        }
      });
    }
  }
}

export default ParallaxGallery;
